$font-gotham-list: "Black", "BlackIta", "Bold", "BoldIta", "Book", "BookIta", "Light", "LightIta", "Medium", "MediumIta", "Thin", "ThinIta", "Ultra", "UltraIta", "XLight", "XLightIta";

@mixin font-face($name, $source) {
  @font-face {
    font-family: quote($name);
    src: url(quote(#{$source + '.woff2' })) format("woff2"),
    url(quote(#{$source + '.woff' })) format("woff");
    font-style: normal;
    font-weight: normal;
    font-display: swap;
  }
}

@each $name in $font-gotham-list {
  $gotham-font: #{"Gotham-" + $name};
  @include font-face($gotham-font, #{$gotham-font-path + "subset-" + $gotham-font});
}

// https://legalmatch.atlassian.net/wiki/spaces/LSR/pages/94700715/Mobile+and+Desktop+Redesign+Standards
$grid-breakpoints: (
        base: 0,
        tn: 320px,
        xs: 480px,
        ss: 576px,
        sm: 768px,
        md: 992px,
        lg: 1200px
);

$container-max-widths: (
        tn: 320px,
        xs: 480px,
        ss: 576px,
        sm: 744px,
        md: 960px,
        lg: 1050px
);

$grid-gutter-width: 0;

$modal-lg:                          900px;
$modal-md:                          600px;

$enable-caret: false;
$enable-gradients: true;

$yellow: #d68d00;
$blue: #02397b;
$blue-dark: #032c6b;
$blue-light: #7BB6D4;
$primary: #337ab7;
$body-color: #232222;
$link-base-color: #2167a6;
$link-hover-color: #23527c;
$link-decoration: underline;
$white: #fff;

$font-family-sans-serif: Arial, sans-serif;

$font-family-base: Gotham-Book, arial, sans-serif;
$font-size-base: .875rem; // 14px (based on browser default 16px) https://daniellamb.com/experiments/px-to-rem-calc/

@import "@font-awesome/scss/_variables";

$fa-font-path: "/dist/fonts";
$gotham-font-path: "/css/fonts/gotham-fonts/";

$c-case-intake-modal-digit-size: 53px;

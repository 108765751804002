@import "bs4/variables";
@import "bs4/mixins";
@import "@bootstrap/scss/functions";
@import "@bootstrap/scss/variables";
@import "@bootstrap/scss/mixins";
@import "@bootstrap/scss/utilities";
@import "@bootstrap/scss/reboot";
@import "@bootstrap/scss/tables";
@import "@bootstrap/scss/transitions";
@import "@poly-fluid-sizing/poly-fluid-sizing";
@import "bs4/gotham-fonts";

body {
  min-width: 320px;
}